import React, { Component } from 'react';

class HR extends Component {
    render() {
        return (
            <div className="hr">
                <div className="container">
                    <hr />
                </div>
            </div>
        )
    }
}

export default HR;
